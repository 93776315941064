<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isCreateShortProductSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-create-product-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            Crear producto
          </h5>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- product Name -->
            <validation-provider
              #default="validationContext"
              name="Nombre del producto"
              rules="required"
            >
              <b-form-group
                label="Nombre del producto"
                label-for="product-name"
              >
                <b-form-input
                  id="product-name"
                  v-model="objLocal.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- product Code -->
            <validation-provider
              #default="validationContext"
              name="Código del producto"
              rules="required"
            >
              <b-form-group
                label="Código del producto"
                label-for="product-code"
              >
                <b-form-input
                  id="product-code"
                  v-model="objLocal.code"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Price PEN -->
            <validation-provider
              #default="validationContext"
              name="Precio soles"
              rules="required"
            >
              <b-form-group
                label="Precio soles"
                label-for="product-pricePEN"
              >
                <b-form-input
                  id="product-pricePEN"
                  v-model="objLocal.pricePEN"
                  :state="getValidationState(validationContext)"
                  type="number"
                  step="0.000001"
                  trim
                  @keyup="pricePENListen"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Price USD -->
            <b-form-group
              label="Precio dólares"
              label-for="price_usd"
            >
              <b-form-input
                id="price_usd"
                v-model="objLocal.priceUSD"
                trim
                type="number"
                step="0.000001"
                @keyup="priceUSDListen"
              />
            </b-form-group>

            <!-- Category -->
            <validation-provider
              #default="validationContext"
              name="Categoria"
              rules="required"
            >
              <!-- Category -->
              <b-form-group
                label="Categoria"
                label-for="category"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="objLocal.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productsCategoriesList"
                  label="name"
                  :reduce="val => val.id"
                  class="assignee-selector"
                  input-id="category"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Brand -->
            <validation-provider
              #default="validationContext"
              name="Marca"
              rules="required"
            >
              <b-form-group
                label="Marca"
                label-for="brand"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="objLocal.brand"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productsBrandList"
                  label="name"
                  :reduce="val => val.id"
                  class="assignee-selector"
                  input-id="brand"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Stock -->
            <validation-provider
              #default="validationContext"
              name="Stock"
              rules="required"
            >
              <b-form-group
                label="Stock"
                label-for="product-stock"
              >
                <b-form-input
                  id="product-stock"
                  v-model="objLocal.stock"
                  :state="getValidationState(validationContext)"
                  type="number"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Discount -->
            <validation-provider
              #default="validationContext"
              name="Descuento"
              rules="required"
            >
              <b-form-group
                label="Descuento"
                label-for="product-discount"
              >
                <b-form-input
                  id="product-discount"
                  v-model="objLocal.discount"
                  :state="getValidationState(validationContext)"
                  type="number"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <b-form-group
              label="Descripcion del producto"
              label-for="task-description"
            >
              <quill-editor
                id="quil-content"
                v-model="objLocal.descriptionInQuotation"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Crear
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="hide()"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import productShortHandler from '@/views/dramox/quotation/components/productShortHandler'
import store from '@/store'
import productStoreModule from '@/views/dramox/product/productStoreModule'
import productHandler from '@/views/dramox/product/productHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isCreateShortProductSidebarActive',
    event: 'update:is-create-product-sidebar-active',
  },
  props: {
    isCreateShortProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataForm: {
      type: Object,
      required: true,
    },
    clearData: {
      type: Function,
      required: true,
    },
    exchangeRateValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-products'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const {
      objLocal,
      resetLocal,

      // UI
      onSubmit,

      // pricePENListen,
      // priceUSDListen,

    } = productShortHandler(toRefs(props), emit)

    const pricePENListen = val => {
      objLocal.value.priceUSD = (+val.target.value / props.exchangeRateValue).toFixed(6)
    }

    const priceUSDListen = val => {
      objLocal.value.pricePEN = (+val.target.value * props.exchangeRateValue).toFixed(6)
    }

    const {
      productsCategoriesList,
      productsBrandList,
    } = productHandler()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetLocal, props.clearData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Escribe tu descripcion',
    }

    return {
      productsCategoriesList,
      productsBrandList,
      // Add New
      objLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,

      // Filter/Formatter
      avatarText,

      pricePENListen,
      priceUSDListen,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
