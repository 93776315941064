import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function serviceShortHandler(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const objLocal = ref(JSON.parse(JSON.stringify(props.dataForm.value)))
  const resetLocal = () => {
    objLocal.value = JSON.parse(JSON.stringify(props.dataForm.value))
  }
  watch(props.dataForm, () => {
    resetLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const taskData = JSON.parse(JSON.stringify(objLocal))

    emit('add-service', taskData.value)

    // Close sidebar
    emit('update:is-create-service-sidebar-active', false)
  }
  const pricePENListen = val => {
    objLocal.value.priceUSD = (+val.target.value / props.exchangeRateValue.value).toFixed(6)
  }

  const priceUSDListen = val => {
    objLocal.value.pricePEN = (+val.target.value * props.exchangeRateValue.value).toFixed(6)
  }

  return {
    objLocal,
    resetLocal,

    // UI
    onSubmit,
    pricePENListen,
    priceUSDListen,
  }
}
