import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuotations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/quotations', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNotificationQuotations(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/quotations/notifications')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNotificationAll(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/quotations/notifications/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResources(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/quotations/resources')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContacts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/contacts/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/products/quotation')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServices(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/services/quotation')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuotation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/quotation/${id}/edit`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPreviewQuotation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/quotation/${id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addQuotation(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/quotation/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuotation(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/quotation/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQuotation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/quotation/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeQuotationToSale(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/quotation/${id}/sale`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendEmail(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/contact/send/email', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelSale(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/sale/${id}/cancel`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    showHistoryQuotation(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/quotation/histories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExchangeRate(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/setting/exchange-rate')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addQuotationProduct(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/product/store-short', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addQuotationService(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/service/store-short', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
