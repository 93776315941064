<template>
  <section class="invoice-add-wrapper">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        class=""
        @submit.prevent="handleSubmit(saveQuotation)"
      >

        <b-row class="invoice-add">
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <b-row align-h="between">
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        Dramox
                      </h3>
                    </div>
                    <!-- Subject -->
                    <validation-provider
                      #default="validationContext"
                      name="Asunto"
                      rules="required"
                    >
                      <b-form-group
                        label="Asunto"
                        label-for="subject"
                      >
                        <b-form-input
                          id="subject"
                          v-model="quotationData.subject"
                          :state="getValidationState(validationContext)"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <div class="invoice-number-date mt-md-0 mt-2">
                      <div class="d-flex align-items-center">
                        <span class="title">
                          Fecha Vencimiento:
                        </span>

                        <!-- Expiration date -->
                        <validation-provider
                          #default="validationContext"
                          name="Fecha"
                          rules="required"
                        >
                          <b-form-group
                            label-for="expiration-date"
                            :state="getValidationState(validationContext)"
                          >
                            <flat-pickr
                              id="expiration-date"
                              v-model="quotationData.expirationDate"
                              class="form-control invoice-edit-input"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <!-- Contact -->
                    <validation-provider
                      #default="validationContext"
                      name="Contacto"
                      rules="required"
                    >
                      <b-form-group
                        label="Contacto"
                        label-for="contact-quotation"
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select Client -->
                        <v-select
                          v-model="quotationData.clientId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="clients"
                          label="full_name"
                          input-id="contact-quotation"
                          :reduce="option => option.id"
                          :clearable="false"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                  >
                    <!-- Delivery time -->
                    <validation-provider
                      #default="validationContext"
                      name="Tiempo de entrega"
                      rules="required"
                    >
                      <b-form-group
                        label="Tiempo de entrega"
                        label-for="delivery-time"
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select delivery time -->
                        <v-select
                          v-model="quotationData.deliveryTime"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="deliveryTimesOptions"
                          label="display"
                          input-id="delivery-time"
                          class="payment-selector"
                          :reduce="option => option.value"
                          :clearable="false"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="3"
                  >
                    <!-- Way to Pay -->
                    <validation-provider
                      #default="validationContext"
                      name="Forma de pago"
                      rules="required"
                    >
                      <b-form-group
                        label="Forma de pago"
                        label-for="payment-method"
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select method payment time -->
                        <v-select
                          v-model="quotationData.methodPayment"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="methodPaymentsOptions"
                          input-id="payment-method"
                          class="payment-selector"
                          label="display"
                          :reduce="option => option.value"
                          :clearable="false"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="3"
                  >
                    <!-- PaymentMethod -->
                    <validation-provider
                      #default="validationContext"
                      name="Metodo de pago"
                      rules="required"
                    >
                      <b-form-group
                        label="Método de pago"
                        label-for="payment_method"
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select method payment time -->
                        <v-select
                          v-model="quotationData.paymentMethod"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paymentsOptions"
                          input-id="payment_method"
                          class="payment-selector"
                          label="name"
                          :reduce="option => option.id"
                          :clearable="false"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">

                <h4>Detalles del artículo</h4>
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in quotationData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="7"
                          >
                            Item
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            Cant.
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Precio venta ({{ currencyData.symbol }})
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Precio neto ({{ currencyData.symbol }})
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>
                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="7"
                          >
                            <label class="d-inline d-lg-none">Item </label>
                            <v-select
                              v-if="item.showProduct"
                              v-model="item.productId"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="products"
                              label="name"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccionar producto"
                              :reduce="val => val.id"
                              @input="element => updateItemForm('product',index, element)"
                            >
                              <template #option="{ photo, name }">
                                <b-avatar
                                  rounded="sm"
                                  :src="photo"
                                />
                                <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                              </template>

                              <template #selected-option="{ photo, name }">
                                <b-avatar
                                  rounded="sm"
                                  :src="photo"
                                  :variant="`light-primary`"
                                  :text="avatarText(name)"
                                />

                                <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                              </template>

                            </v-select>
                            <v-select
                              v-else
                              v-model="item.serviceId"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="services"
                              label="name"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccionar servicio"
                              :reduce="val => val.id"
                              @input="val => updateItemForm('service',index, val)"
                            >
                              <template #option="{ photo, name }">
                                <b-avatar
                                  rounded="sm"
                                  :src="photo"
                                />
                                <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                              </template>

                              <template #selected-option="{ photo, name }">
                                <b-avatar
                                  rounded="sm"
                                  :src="photo"
                                  :variant="`light-primary`"
                                  :text="avatarText(name)"
                                />

                                <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                              </template>
                            </v-select>
                          </b-col>

                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <b-form-input
                              v-model="item.qty"
                              type="number"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Cost</label>
                            <b-form-input
                              v-model="item.cost"
                              type="number"
                              step="0.0001"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Price</label>
                            <p class="mb-1">
                              {{ currencyData.symbol }}{{ (item.cost*(1-item.discount/100) * item.qty).toFixed(2) }}
                            </p>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="8"
                          >
                            <!--                            <p v-html="item.descriptionQuotation" />-->
                            <b-button
                              v-if="item.qty !== 0"
                              variant="flat-primary"
                              size="sm"
                              @click="showDetailItem(item)"
                            >
                              Ver detalle
                            </b-button>
                            <label class="d-inline d-lg-none">Description</label>
                            <b-form-textarea
                              v-model="item.descriptionExtra"
                              rows="1"
                              class="mb-2 mb-lg-0"
                            />
                          </b-col>
                          <b-col lg="2">
                            <p class="mb-0">
                              Descuento: {{ item.discount }}%
                            </p>
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  @click="addNewItemInItemForm('product')"
                >
                  Agregar producto
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="success"
                  @click="addNewItemInItemForm('service')"
                >
                  Agregar servicio
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="12"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal ({{ currencyData.symbol }}):
                        </p>
                        <p class="invoice-total-amount">
                          {{ totalQuotation.toFixed(2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">

                          <feather-icon
                            :id="`form-item-settings-icon-discount`"
                            size="16"
                            icon="SettingsIcon"
                            class="cursor-pointer"
                          />
                          Descuento ({{ quotationData.discountType === 'percentage' ? `${quotationData.discount}%` : currencyData.symbol }}):
                          <!-- Setting Item Form -->
                          <b-popover
                            :ref="`form-item-settings-popover-discount`"
                            :target="`form-item-settings-icon-discount`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group
                                    label="Tipo de descuento"
                                    label-for="setting-type-discount"
                                  >
                                    <v-select
                                      v-model="quotationData.discountType"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="discountTypes"
                                      label="label"
                                      :reduce="option => option.value"
                                      input-id="setting-type-discount"
                                      :clearable="false"
                                    />
                                  </b-form-group>
                                </b-col>

                                <!-- Field: Discount -->
                                <b-col cols="12">
                                  <b-form-group
                                    :label="chooseLabelDiscountType"
                                    :label-for="`setting-item-discount-discount`"
                                  >
                                    <b-form-input
                                      :id="`setting-item-discount-discount`"
                                      v-model="quotationData.discount"
                                      type="number"
                                    />
                                  </b-form-group>
                                </b-col>

                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="outline-primary"
                                    @click="() => {$refs[`form-item-settings-popover-discount`].$emit('close')}"
                                  >
                                    Aplicar
                                  </b-button>
                                  <b-button
                                    variant="outline-secondary"
                                    @click="() => {$refs[`form-item-settings-popover-discount`].$emit('close');quotationData.discount = 0}"
                                  >
                                    Cancelar
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </p>
                        <p class="invoice-total-amount">
                          {{ discountQuotation.toFixed(2) }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Valor venta ({{ currencyData.symbol }}):
                        </p>
                        <p class="invoice-total-amount">
                          {{ subtotalQuotation.toFixed(2) }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          IGV(18%) ({{ currencyData.symbol }}):
                        </p>
                        <p class="invoice-total-amount">
                          {{ taxesQuotation.toFixed(2) }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total a pagar ({{ currencyData.symbol }}):
                        </p>
                        <p class="invoice-total-amount">
                          {{ totalPayQuotation.toFixed(2) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Observaciones: </span>
                <b-form-textarea v-model="quotationData.observation" />
              </b-card-body>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >

            <!-- Action Buttons -->
            <b-card>
              <!-- Button: Print -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="success"
                block
                type="submit"
              >
                Guardar
              </b-button>
              <!-- Button: Print -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
                @click="$router.push({ name: 'quotations'})"
              >
                Cancelar
              </b-button>
            </b-card>
            <b-card>
              <!-- Button: Create product -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                block
                @click="isCreateShortProductSidebarActive = true"
              >
                Crear producto rápido
              </b-button>
              <!-- Button: Create service -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="success"
                block
                @click="isCreateServiceSidebarActive = true"
              >
                Crear servicio rápido
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      v-model="modalShowDetail"
      ok-only
      size="lg"
      ok-title="Cerrar"
    >
      <h5>{{ modalShowDetailItemData.name }}</h5>
      <span v-html="modalShowDetailItemData.description_quotation" />
      <b-img
        thumbnail
        fluid
        :src="modalShowDetailItemData.photo"
        alt="Foto producto"
      />
    </b-modal>
    <create-product-short-sidebar
      v-model="isCreateShortProductSidebarActive"
      :data-form="productData"
      :exchange-rate-value="exchangeRateValue"
      :clear-data="resetProductData"
      @add-product="addProductShort"
    />
    <create-service-short-sidebar
      v-model="isCreateServiceSidebarActive"
      :data-form="serviceData"
      :clear-data="resetServiceData"
      :exchange-rate-value="exchangeRateValue"
      @add-service="addServiceShort"
    />
  </section>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BPopover, BFormInvalidFeedback,
  BModal, BAvatar, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import quotationStoreModule from '@/views/dramox/quotation/quotationStoreModule'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Logo from '@core/layouts/components/Logo.vue'
import CreateProductShortSidebar from '@/views/dramox/quotation/components/CreateProductShortSidebar'
import CreateServiceShortSidebar from '@/views/dramox/quotation/components/CreateServiceShortSidebar'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    CreateServiceShortSidebar,
    CreateProductShortSidebar,
    Logo,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    BFormInvalidFeedback,
    BModal,
    BAvatar,
    BImg,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
      locale: SpanishLocale,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm(value) {
      const item = JSON.parse(JSON.stringify(this.itemFormBlankItem))
      if (value === 'service') {
        item.showService = true
      } else {
        item.showProduct = true
      }
      this.$refs.form.style.overflow = 'hidden'
      this.quotationData.items.push(item)

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight + 30)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.quotationData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-quotations'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, quotationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const modalShowDetail = ref(false)
    const modalShowDetailItemData = ref({ })
    const toast = useToast()
    const { route } = useRouter()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isCreateShortProductSidebarActive = ref(false)
    const isCreateServiceSidebarActive = ref(false)

    const productDataLocal = {
      pricePEN: null,
      priceUSD: null,
      name: '',
      stock: null,
      discount: null,
      code: '',
      descriptionInQuotation: '',
    }

    const productData = ref(JSON.parse(JSON.stringify(productDataLocal)))
    const resetProductData = () => {
      productData.value = JSON.parse(JSON.stringify(productDataLocal))
    }

    const serviceDataLocal = {
      name: '',
      pricePEN: null,
      priceUSD: null,
      discount: null,
      descriptionInQuotation: '',
    }
    const serviceData = ref(JSON.parse(JSON.stringify(serviceDataLocal)))
    const resetServiceData = () => {
      serviceData.value = JSON.parse(JSON.stringify(serviceDataLocal))
    }
    const exchangeRateValue = ref(0)

    const deliveryTimesOptions = ref([])
    const methodPaymentsOptions = ref([])
    const paymentsOptions = ref([])
    const clients = ref([])
    const products = ref([])
    const services = ref([])

    const clientIdParams = route.value.query.contactId

    const quotationData = ref({
      clientId: null,
      subject: '',
      expirationDate: null,
      deliveryTime: '',
      methodPayment: '',
      paymentMethod: '',
      // ? Set single Item in form for adding data
      items: [],
      observation: '',
      discount: 0,
      discountType: 'percentage',
    })

    store.dispatch('app-quotations/fetchResources')
      .then(response => {
        const { deliveryTimes, methodPayments, paymentMethod } = response.data.payload.results
        deliveryTimesOptions.value = deliveryTimes
        methodPaymentsOptions.value = methodPayments
        paymentsOptions.value = paymentMethod
      })

    store.dispatch('app-quotations/fetchContacts')
      .then(response => {
        const { results } = response.data.payload
        clients.value = results

        quotationData.value.clientId = clientIdParams ? +clientIdParams : null
      })

    store.dispatch('app-quotations/fetchProducts')
      .then(response => {
        const { results } = response.data.payload
        products.value = results
      })

    store.dispatch('app-quotations/fetchServices')
      .then(response => {
        const { results } = response.data.payload
        services.value = results
      })

    store
      .dispatch('app-quotations/fetchExchangeRate')
      .then(response => {
        const { exchange_rate: exchangeRate } = response.data.payload.results
        exchangeRateValue.value = exchangeRate
      })
      .catch(e => {
        console.log(e, 'error fetch excnage rate')
      })

    const itemFormBlankItem = {
      serviceId: null,
      productId: null,
      cost: 0,
      qty: 0,
      description: '',
      showProduct: false,
      showService: false,
      discount: 0,
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const totalQuotation = computed(() => +(quotationData.value.items.reduce((acc, sum) => acc + ((sum.cost * (1 - sum.discount / 100)) * sum.qty), 0)).toFixed(2))
    const discountQuotation = computed(() => {
      if (quotationData.value.discountType === 'percentage') {
        return +(totalQuotation.value * (quotationData.value.discount / 100)).toFixed(2)
      }
      return +(+quotationData.value.discount).toFixed(2)
    })
    const subtotalQuotation = computed(() => {
      if (quotationData.value.discountType === 'percentage') {
        return +((1 - quotationData.value.discount / 100) * totalQuotation.value).toFixed(2)
      }
      return +(totalQuotation.value - quotationData.value.discount).toFixed(2)
    })
    const taxesQuotation = computed(() => +(0.18 * subtotalQuotation.value).toFixed(2))
    const totalPayQuotation = computed(() => +(taxesQuotation.value + subtotalQuotation.value).toFixed(2))
    const currencyData = computed(() => ({
      symbol: router.currentRoute.params.currency === 'pen' ? 'S/' : '$',
      currency: router.currentRoute.params.currency,
    }))

    const updateItemForm = (type, index, val) => {
      let searchObject = {}
      let productId = null
      let serviceId = null

      if (type === 'product') {
        searchObject = products.value.find(e => e.id === val)
        productId = val
      }

      if (type === 'service') {
        searchObject = services.value.find(e => e.id === val)
        serviceId = val
      }
      quotationData.value.items[index].productId = productId
      quotationData.value.items[index].serviceId = serviceId
      quotationData.value.items[index].cost = router.currentRoute.params.currency === 'pen' ? searchObject.price_pen : searchObject.price_usd
      quotationData.value.items[index].qty = 1
      quotationData.value.items[index].descriptionExtra = ''
      quotationData.value.items[index].discount = searchObject.discount
      quotationData.value.items[index].descriptionQuotation = searchObject.description_quotation
    }

    const saveQuotation = () => {
      if (quotationData.value.items.some(e => +e.qty === 0)) {
        showNotification({ title: 'Completa los datos de los items agregados', icon: 'AlertTriangleIcon', variant: 'danger' })
        return
      }
      const totalPaymentValue = totalPayQuotation.value
      const subtotalValue = subtotalQuotation.value

      const body = {
        ...quotationData.value,
        total: totalPaymentValue,
        subtotal: subtotalValue,
        currency: currencyData.value.currency,
        igv: 18.00,
      }

      store.dispatch('app-quotations/addQuotation', { body })
        .then(() => {
          router.push({ name: 'quotations' })
          showNotification({ title: 'Cotización creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const addProductShort = val => {
      store.dispatch('app-quotations/addQuotationProduct', { body: val })
        .then(response => {
          const { product } = response.data.payload.results
          products.value.unshift(product)
          showNotification({ title: 'Producto creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const addServiceShort = val => {
      store.dispatch('app-quotations/addQuotationService', { body: val })
        .then(response => {
          const { service } = response.data.payload.results
          services.value.unshift(service)
          showNotification({ title: 'Servicio creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const showDetailItem = item => {
      let objData
      if (item.productId) {
        objData = products.value.find(e => e.id === item.productId)
      } else {
        objData = services.value.find(e => e.id === item.serviceId)
      }
      modalShowDetailItemData.value = objData
      modalShowDetail.value = true
    }

    const discountTypes = [
      {
        label: 'Porcentaje',
        value: 'percentage',
      },
      {
        label: 'Monto Fijo',
        value: 'amount',
      },
    ]

    const chooseLabelDiscountType = computed(() => {
      const discountType = discountTypes.find(e => e.value === quotationData.value.discountType).value
      if (discountType === 'percentage') {
        return 'Descuento (%)'
      }
      return `Descuento (${currencyData.value.symbol})`
    })

    return {
      quotationData,
      clients,
      updateItemForm,
      itemFormBlankItem,

      deliveryTimesOptions,
      methodPaymentsOptions,
      products,
      services,

      saveQuotation,
      totalQuotation,
      discountQuotation,
      subtotalQuotation,
      taxesQuotation,
      totalPayQuotation,
      currencyData,
      paymentsOptions,

      refFormObserver,
      getValidationState,
      resetForm,

      isCreateShortProductSidebarActive,
      isCreateServiceSidebarActive,

      productData,
      serviceData,

      exchangeRateValue,

      resetProductData,
      resetServiceData,
      addProductShort,
      addServiceShort,

      modalShowDetail,
      showDetailItem,
      modalShowDetailItemData,

      avatarText,

      discountTypes,
      chooseLabelDiscountType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
