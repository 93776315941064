import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function productShortHandler(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const objLocal = ref(JSON.parse(JSON.stringify(props.dataForm.value)))
  const resetLocal = () => {
    objLocal.value = JSON.parse(JSON.stringify(props.dataForm.value))
  }
  watch(props.dataForm, () => {
    resetLocal()
  })

  // const pricePENListen = val => {
  //   objLocal.value.priceUSD = (+val.target.value / props.exchangeRateValue.value).toFixed(6)
  //   console.log(objLocal.value)
  // }
  //
  // const priceUSDListen = val => {
  //   objLocal.value.pricePEN = (+val.target.value * props.exchangeRateValue.value).toFixed(6)
  //   console.log(objLocal.value)
  // }

  const onSubmit = () => {
    const taskData = JSON.parse(JSON.stringify(objLocal))
    emit('add-product', taskData.value)

    // Close sidebar
    emit('update:is-create-product-sidebar-active', false)
  }

  return {
    objLocal,
    resetLocal,

    // UI
    onSubmit,

    // pricePENListen,
    // priceUSDListen,
  }
}
